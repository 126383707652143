<template>
  <div class="index-wrapper">
    <div class="screen-wrapper">
      <img class="bg" src="@/assets/image/bg.jpg" />
      <div class="content">
        <div class="left-box">
          <!--<div class="title">AI优聘智能面试可视化大屏</div>-->
          <div class="data-box">
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/heart-icon.png" />
              </div>
              <div class="num-box">
                <div class="num heart-num">
                  <span>
                    <countTo
                      :startVal="(excitedNum + calmNum + concernNum) / 3"
                      :endVal="(excitedNum + calmNum + concernNum) / 3"
                      :duration="2000"
                      :decimals="2"
                    ></countTo>
                  </span>
                </div>
                <div class="text">心理指数</div>
              </div>
            </div>
            <div class="item">
              <dv-decoration-4 class="line-box"></dv-decoration-4>
              <div class="desc-box">
                <div class="num-box">
                  <div class="schedule">
                    <div class="active"></div>
                  </div>
                  <div class="text-box">
                    <div class="num">
                      <span>{{ speakingRate ? parseInt(speakingRate) : 0 }}</span>
                      字/分钟
                    </div>
                    <div class="text">语速</div>
                  </div>
                </div>
                <div class="num-box">
                  <div class="schedule">
                    <div class="active"></div>
                  </div>
                  <div class="text-box">
                    <div class="num">
                      <span>{{ speakingRate ? ((speakingRate / 1000) * 100).toFixed(2) : 0 }}</span>
                      <div class="icon">
                        <img src="@/assets/image/triangle-icon.png" />
                      </div>
                    </div>
                    <div class="text">流畅度</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mental-box">
            <div class="item">
              <div class="score">
                <countTo :startVal="excitedNum" :endVal="excitedNum" :duration="2000"></countTo>
              </div>
              <div class="text-box">
                <div class="dot-box">
                  <div class="dot"></div>
                  <div class="text">excited</div>
                </div>
                <div class="common-style">激动</div>
              </div>
              <div ref="tableCanvas" class="table-box">
                <canvas id="can1" class="table-canvas">Canvas心电图</canvas>
                <div class="row">
                  <div class="line" v-for="index in 10" :key="index"></div>
                </div>
                <div class="vertical">
                  <div class="line" v-for="index in 3" :key="index"></div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="score">
                <countTo :startVal="calmNum" :endVal="calmNum" :duration="2000"></countTo>
              </div>
              <div class="text-box">
                <div class="dot-box">
                  <div class="dot"></div>
                  <div class="text">normal</div>
                </div>
                <div class="common-style">平静</div>
              </div>
              <div class="table-box">
                <canvas id="can2" class="table-canvas">Canvas心电图</canvas>
                <div class="row">
                  <div class="line" v-for="index in 10" :key="index"></div>
                </div>
                <div class="vertical">
                  <div class="line" v-for="index in 3" :key="index"></div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="score">
                <countTo :startVal="concernNum" :endVal="concernNum" :duration="2000"></countTo>
              </div>
              <div class="text-box">
                <div class="dot-box">
                  <div class="dot"></div>
                  <div class="text">concern</div>
                </div>
                <div class="common-style">忧虑</div>
              </div>
              <div class="table-box">
                <canvas id="can3" class="table-canvas">Canvas心电图</canvas>
                <div class="row">
                  <div class="line" v-for="index in 10" :key="index"></div>
                </div>
                <div class="vertical">
                  <div class="line" v-for="index in 3" :key="index"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="answer-box">
            <div class="item">
              <div class="item-left">
                <div class="common-style">分析应变能力水平</div>
                <div class="desc">求职者在面试时应对HR各类问题的反应力</div>
                <div class="hint-box">
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">ANALYSIS</div>
                  </div>
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">LOGIC</div>
                  </div>
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">THINKING</div>
                  </div>
                </div>
              </div>
              <div class="item-right">
                <div class="num">
                  <countTo :startVal="0" :endVal="250" :duration="2000"></countTo>
                </div>
                <div class="text">进入阶段</div>
              </div>
              <div class="line"></div>
            </div>
            <div class="item">
              <div class="item-left">
                <div class="common-style">态度情绪变化</div>
                <div class="desc">求职者面试时的态度以及情绪变化</div>
                <div class="hint-box">
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">ATTITUDE</div>
                  </div>
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">EMOTION</div>
                  </div>
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">COORDINATE</div>
                  </div>
                </div>
              </div>
              <div class="item-right">
                <div class="num">
                  <countTo :startVal="0" :endVal="115" :duration="2000"></countTo>
                </div>
                <div class="text">正常</div>
              </div>
              <div class="line"></div>
            </div>
            <div class="item">
              <div class="item-left">
                <div class="common-style">沟通表达流畅度</div>
                <div class="desc">求职者在面试时回答问题的表达能力水平</div>
                <div class="hint-box">
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">EXPRESSION</div>
                  </div>
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">COMMUNICATE</div>
                  </div>
                  <div class="hint">
                    <div class="icon">
                      <img src="@/assets/image/triangle-icon1.png" />
                    </div>
                    <div class="text">FLUENCY</div>
                  </div>
                </div>
              </div>
              <div class="item-right">
                <div class="num">
                  <countTo :startVal="0" :endVal="60" :duration="2000"></countTo>
                </div>
                <div class="text">普通</div>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="mobile-phone-frame">
            <div class="flat-bangs-view">
              <img class="flat-bangs" src="@/assets/image/flat-bangs.png" />
            </div>
            <div class="interview-view">
              <div class="my-video">
                <dv-loading v-if="!is_click" class="my-video-loading">
                  <div v-if="interviewId === 0 && onTestStartTime > 0" class="loading-text">
                    准备答题...{{ onTestStartTime }}s
                  </div>
                  <div v-if="interviewId || onTestStartTime === 0" class="loading-text">
                    载入中...
                  </div>
                </dv-loading>
                <div
                  v-if="callBackObj"
                  class="text-view"
                  :class="{ 'view-border': noFigureSec >= 10 }"
                >
                  <div class="title">{{ callBackObj.title }}</div>
                  <div class="text">
                    {{ indexCurr + 1 }}/{{ callBackObj.question.length }}
                    {{ callBackObj.question[currentIndex].title }}
                  </div>
                  <div class="bottom">
                    <div class="left">
                      <ul>
                        <li>
                          <div class="groove">
                            <div class="groove-line" :style="{ height: excitedNum + '%' }"></div>
                          </div>
                          <div class="bottom-text">激动</div>
                        </li>
                        <li>
                          <div class="groove">
                            <div class="groove-line" :style="{ height: calmNum + '%' }"></div>
                          </div>
                          <div class="bottom-text">平静</div>
                        </li>
                        <li>
                          <div class="groove">
                            <div class="groove-line" :style="{ height: concernNum + '%' }"></div>
                          </div>
                          <div class="bottom-text">低落</div>
                        </li>
                      </ul>
                    </div>
                    <div class="right">{{ h }}:{{ m }}:{{ s }}</div>
                  </div>
                </div>
                <video
                  id="myVideo"
                  muted
                  loop
                  poster="https://flow.czlks.com/ai-interview-app/camera-bg.png"
                  @loadedmetadata="fnRunFaceExpression"
                  playsinline
                  autoplay
                />
              </div>
              <canvas id="myCanvas" />
            </div>
            <img class="mobile-img" src="@/assets/image/phone-icon.png" />
          </div>
          <div class="btn-wrapper">
            <div @click="onReloadFn" class="btn-box">
              <img src="@/assets/image/again-btn.png" />
            </div>
            <div
              v-if="(interviewId === 0 && onTestStartTime === 0) || interviewId"
              @click="onSaveFn"
              class="btn-box"
            >
              <img
                v-if="callBackObj && indexCurr + 1 < callBackObj.question.length"
                src="@/assets/image/next-btn.png"
              />
              <img
                v-if="callBackObj && indexCurr + 1 === callBackObj.question.length"
                src="@/assets/image/submit-btn.png"
              />
            </div>
            <div
              v-if="interviewId === 0 && onTestStartTime > 0"
              @click="onTestStartAnswerFn(false)"
              class="btn-box"
            >
              <img src="@/assets/image/start-btn6.png" />
            </div>
          </div>
        </div>
        <div class="right-box">
          <!--<div class="daily-box">
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/clock-icon.png" />
              </div>
              <div class="time-box">
                <div class="hour">{{ todayTime }}</div>
                <div class="second">
                  <div class="text">{{ todayS }}</div>
                  <div class="line"></div>
                </div>
              </div>
            </div>
            <div class="right-icon">
              <img src="@/assets/image/right-icon.png" />
            </div>
            <div v-if="weather" class="item">
              <div class="icon">
                <img v-if="weather.icon === '0'" src="@/assets/image/weather/W0.png" />
                <img v-if="weather.icon === '1'" src="@/assets/image/weather/W1.png" />
                <img v-if="weather.icon === '2'" src="@/assets/image/weather/W2.png" />
                <img v-if="weather.icon === '3'" src="@/assets/image/weather/W3.png" />
                <img v-if="weather.icon === '4'" src="@/assets/image/weather/W4.png" />
                <img v-if="weather.icon === '5'" src="@/assets/image/weather/W5.png" />
                <img v-if="weather.icon === '6'" src="@/assets/image/weather/W6.png" />
                <img v-if="weather.icon === '7'" src="@/assets/image/weather/W7.png" />
                <img v-if="weather.icon === '8'" src="@/assets/image/weather/W8.png" />
                <img v-if="weather.icon === '9'" src="@/assets/image/weather/W9.png" />
                <img v-if="weather.icon === '10'" src="@/assets/image/weather/W10.png" />
                <img v-if="weather.icon === '13'" src="@/assets/image/weather/W13.png" />
                <img v-if="weather.icon === '14'" src="@/assets/image/weather/W14.png" />
                <img v-if="weather.icon === '15'" src="@/assets/image/weather/W15.png" />
                <img v-if="weather.icon === '16'" src="@/assets/image/weather/W16.png" />
                <img v-if="weather.icon === '17'" src="@/assets/image/weather/W17.png" />
                <img v-if="weather.icon === '18'" src="@/assets/image/weather/W18.png" />
                <img v-if="weather.icon === '19'" src="@/assets/image/weather/W19.png" />
                <img v-if="weather.icon === '20'" src="@/assets/image/weather/W20.png" />
                <img v-if="weather.icon === '29'" src="@/assets/image/weather/W29.png" />
                <img v-if="weather.icon === '30'" src="@/assets/image/weather/W30.png" />
                <img v-if="weather.icon === '31'" src="@/assets/image/weather/W31.png" />
                <img v-if="weather.icon === '32'" src="@/assets/image/weather/W32.png" />
                <img v-if="weather.icon === '33'" src="@/assets/image/weather/W33.png" />
                <img v-if="weather.icon === '34'" src="@/assets/image/weather/W34.png" />
                <img v-if="weather.icon === '35'" src="@/assets/image/weather/W35.png" />
                <img v-if="weather.icon === '36'" src="@/assets/image/weather/W36.png" />
                <img v-if="weather.icon === '44'" src="@/assets/image/weather/W44.png" />
                <img v-if="weather.icon === '45'" src="@/assets/image/weather/W45.png" />
                <img v-if="weather.icon === '46'" src="@/assets/image/weather/W46.png" />
              </div>
              <div class="num"><span>{{ weather.temp }}</span>℃</div>
            </div>
          </div>-->
          <div class="score-data">
            <div class="score-box">
              <div class="score">
                <span>
                  <countTo
                    :startVal="overallRating"
                    :endVal="overallRating"
                    :duration="2000"
                    :decimals="2"
                  ></countTo>
                </span>
              </div>
              <div class="text">
                <span>面试评分</span>
                综合分值
              </div>
            </div>
            <div class="text-box">
              <!--<div class="btn-box">
                <div class="btn">回放</div>
                <div class="btn green-btn">实时</div>
              </div>-->
              <div class="text">岗位均值区间（55.27-69.06）</div>
              <div class="block-box">
                <div class="block"></div>
                <div class="block"></div>
                <div class="block"></div>
                <div class="block"></div>
                <div class="block"></div>
                <div class="block"></div>
                <div class="block last-block"></div>
                <div class="line"></div>
              </div>
            </div>
          </div>
          <div class="condition-box">
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/icon-1.png" />
              </div>
              <div class="text">态度表现</div>
              <div class="num">
                <countTo
                  :startVal="parseInt(concernNum)"
                  :endVal="parseInt(concernNum)"
                  :duration="2000"
                ></countTo>
                %
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/icon-2.png" />
              </div>
              <div class="text">问题回答</div>
              <div class="num">
                <countTo
                  :startVal="parseInt(concernNum / 2)"
                  :endVal="parseInt(concernNum / 2)"
                  :duration="2000"
                ></countTo>
                %
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/icon-3.png" />
              </div>
              <div class="text">专业知识</div>
              <div class="num">
                <countTo
                  :startVal="parseInt(calmNum / 2)"
                  :endVal="parseInt(calmNum / 2)"
                  :duration="2000"
                ></countTo>
                %
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/icon-4.png" />
              </div>
              <div class="text">岗位匹配度</div>
              <div class="num">
                <countTo :startVal="0" :endVal="averageScoreFn(1.3)" :duration="2000"></countTo>
                %
              </div>
            </div>
            <div class="item">
              <div class="icon">
                <img src="@/assets/image/icon-5.png" />
              </div>
              <div class="text">人际交往</div>
              <div class="num">
                <countTo :startVal="0" :endVal="averageScoreFn(1.7)" :duration="2000"></countTo>
                %
              </div>
            </div>
          </div>
          <div class="item-title">
            <span>问题</span>
            回答情况
          </div>
          <div v-if="callBackObj && callBackObj.question.length" class="problem-box">
            <div class="line-box">
              <div v-for="(item, index) in callBackObj.question" class="line" :key="index">
                <div
                  class="line-view"
                  :class="{ active: currentIndex >= index }"
                  :style="{
                    height: isShowH ? submitObj.answer_video_score[index] / 2 + 'px' : '0',
                    transitionDelay: index * 0.15 + 's',
                  }"
                ></div>
              </div>
            </div>
            <div class="text-box">
              <div v-for="(item, index) in callBackObj.question" class="text" :key="index">
                第{{ index + 1 }}题
              </div>
            </div>
          </div>
          <div class="item-title">
            <span>能力</span>
            分析
          </div>
          <div class="ability-box">
            <div class="item">
              <dv-decoration-9 class="num" :color="['#3bff72', '#01e1fb']">
                <countTo :startVal="0" :endVal="averageScoreFn(1.1)" :duration="2000"></countTo>
                分
              </dv-decoration-9>
              <div class="text">忠诚度</div>
            </div>
            <div class="item">
              <dv-decoration-9 class="num" :color="['#3bff72', '#01e1fb']">
                <countTo :startVal="0" :endVal="averageScoreFn(1.6)" :duration="2000"></countTo>
                分
              </dv-decoration-9>
              <div class="text">项目经验</div>
            </div>
            <div class="item">
              <dv-decoration-9 class="num" :color="['#3bff72', '#01e1fb']">
                <countTo :startVal="0" :endVal="averageScoreFn(1.4)" :duration="2000"></countTo>
                分
              </dv-decoration-9>
              <div class="text">管理经验</div>
            </div>
            <div class="item">
              <dv-decoration-9 class="num" :color="['#3bff72', '#01e1fb']">
                <countTo :startVal="0" :endVal="averageScoreFn(1.8)" :duration="2000"></countTo>
                分
              </dv-decoration-9>
              <div class="text">背景</div>
            </div>
          </div>
          <div class="item-title">题库</div>
          <div v-if="callBackObj" class="topic-list">
            <ul v-if="callBackObj.question.length">
              <li
                v-for="(item, index) in callBackObj.question"
                :class="{ active: currentIndex === index }"
                :key="index"
              >
                <dv-border-box-8 class="span" :color="['#3BFF72', '#7cfaa2']">
                  {{ item.indexId }}.{{ item.title }}
                </dv-border-box-8>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边固定导航栏 -->
    <div v-if="interviewId" class="right-btn-wrapper">
      <img class="bg" src="@/assets/image/drawer-box-bg.png" />
      <div class="drawer-btn-box">
        <div class="item" @click="onDrawerFn('job')">
          <img src="@/assets/image/drawer-box-icon1.png" />
          <span>选择岗位</span>
        </div>
        <div class="item" @click="onDrawerFn('report')">
          <img src="@/assets/image/drawer-box-icon2.png" />
          <span>报告列表</span>
        </div>
      </div>
    </div>
    <!-- 未能检测到人脸过久弹窗 -->
    <el-dialog
      title="温馨提示"
      :visible.sync="isShowNoFigure"
      :center="true"
      :modal="false"
      width="30%"
    >
      <span>
        未能检测到人脸过久，{{
          noFigureDuration * 2 - noFigureSec
        }}s后还没有人继续面试，将会自动关闭
      </span>
    </el-dialog>
    <!-- 选择题目右边栏 -->
    <el-drawer
      :visible.sync="drawerState"
      :modal-append-to-body="false"
      :wrapperClosable="interviewId && callBackObj ? true : false"
      :with-header="false"
      :size="drawerType === 'job' ? '36%' : '450px'"
    >
      <img class="bg-img" src="@/assets/image/drawer-box-bg2.png" @click="onHideDrawerFn" />
      <div class="drawer-bg">
        <div v-if="drawerType === 'job'" class="drawer-title">选择面试岗位</div>
        <div v-if="drawerType === 'report'" class="drawer-title">
          面试报告列表
          <div v-if="reportObj.data.length" class="empty-btn" @click="onEmptyDataFn">
            <img src="@/assets/image/empty-icon.png" />
          </div>
        </div>
        <job-list-template
          v-if="drawerType === 'job'"
          :obj="jobObj"
          @getListByCate="getListByCateFn"
          @selectTopic="getInterviewTopicFn"
        />
        <report-template v-if="drawerType === 'report'" :obj="reportObj" />
      </div>
    </el-drawer>

    <ExitApplication v-if="popUp" @ensureFn="ensureFn" @cancelfn="cancelfn" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { upyunUrl, upFileYunUrl } from '@/api/config'
import { formatTime } from '@/utils/index.js'
import CountTo from 'vue-count-to'
import JobListTemplate from '@/components/jobListTemplate'
import ReportTemplate from '@/components/reportTemplate'
import * as faceapi from 'face-api.js'
import ExitApplication from '@/components/ExitApplication.vue'

let timer = null
let timeout = null
let startTimer = null
let electrocardiogramTimer1 = null
let electrocardiogramTimer2 = null
let electrocardiogramTimer3 = null
let electrocardiogramStop1 = null
let electrocardiogramStop2 = null
let electrocardiogramStop3 = null

const myAudio = new Audio()

// 音频转码worker
let recorderWorker = new Worker('./transformpcm.worker.js')
// 记录处理的缓存音频
let buffer = []
let AudioContext = window.AudioContext || window.webkitAudioContext

recorderWorker.onmessage = function (e) {
  buffer.push(...e.data.buffer)
}

// 南宁华为渠道
const nnHuaWeiChannel = '93d6aca9176f3f138776fb4a0101c58b'
const randomNumS = [80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95]

class IatRecorder {
  constructor(config) {
    this.config = config

    // 以下信息在控制台-我的应用-实时语音转写 页面获取
    this.appId = '8cf420a4'
    this.apiKey = 'b59e63d209303d86cabda6279a1216b7'
  }

  start() {
    this.stop()
    if (navigator.getUserMedia && AudioContext) {
      if (!this.recorder) {
        const context = new AudioContext()
        this.context = context
        this.recorder = context.createScriptProcessor(0, 1, 1)

        const getMediaSuccess = stream => {
          this.audioMediaStream = this.context.createMediaStreamSource(stream)
          this.recorder.onaudioprocess = e => {
            this.sendData(e.inputBuffer.getChannelData(0))
          }
          this.connectWebsocket()
        }
        const getMediaFail = () => {
          this.context = null
          this.recorder = null
          this.audioMediaStream = null
          console.log('请求麦克风失败')
        }
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({
              audio: true,
              video: false,
            })
            .then(stream => {
              getMediaSuccess(stream)
            })
            .catch(e => {
              getMediaFail(e)
            })
        } else {
          navigator.getUserMedia(
            {
              audio: true,
              video: false,
            },
            stream => {
              getMediaSuccess(stream)
            },
            function (e) {
              getMediaFail(e)
            }
          )
        }
      } else {
        this.connectWebsocket()
      }
    }
  }

  stop() {
    try {
      this.audioMediaStream.disconnect(this.recorder)
      this.recorder.disconnect()
    } catch (e) {}
  }

  sendData(buffer) {
    recorderWorker.postMessage({
      command: 'transform',
      buffer: buffer,
    })
  }

  // 生成握手参数
  getHandShakeParams() {
    const appId = this.appId
    const secretKey = this.apiKey
    const ts = Math.floor(new Date().getTime() / 1000) // new Date().getTime()/1000 + ''
    const signa = hex_md5(appId + ts) // hex_md5(encodeURIComponent(appId + ts)) // EncryptUtil.HmacSHA1Encrypt(EncryptUtil.MD5(appId + ts), secretKey)
    const signatureSha = CryptoJSNew.HmacSHA1(signa, secretKey)
    let signature = CryptoJS.enc.Base64.stringify(signatureSha)
    signature = encodeURIComponent(signature)
    return '?appid=' + appId + '&ts=' + ts + '&signa=' + signature
  }

  connectWebsocket() {
    let url = 'wss://rtasr.xfyun.cn/v1/ws'
    const urlParam = this.getHandShakeParams()

    url = `${url}${urlParam}`
    if ('WebSocket' in window) {
      this.ws = new WebSocket(url)
    } else if ('MozWebSocket' in window) {
      this.ws = new MozWebSocket(url)
    } else {
      return false
    }
    this.ws.onopen = e => {
      this.audioMediaStream.connect(this.recorder)
      this.recorder.connect(this.context.destination)
      setTimeout(() => {
        this.wsOpened(e)
      }, 500)
      this.config.onStart && this.config.onStart(e)
    }
    this.ws.onmessage = e => {
      this.wsOnMessage(e)
    }
    this.ws.onerror = e => {
      this.stop()
      console.log('关闭连接ws.onerror')
      this.config.onError && this.config.onError(e)
    }
    this.ws.onclose = e => {
      this.stop()
      console.log('关闭连接ws.onclose')
      this.config.onClose && this.config.onClose(e)
    }
  }

  wsOpened() {
    if (this.ws.readyState !== 1) return
    const audioData = buffer.splice(0, 1280)
    this.ws.send(new Int8Array(audioData))
    this.handlerInterval = setInterval(() => {
      // websocket未连接
      if (this.ws.readyState !== 1) {
        clearInterval(this.handlerInterval)
        return
      }
      if (buffer.length === 0) {
        this.ws.send('{"end": true}')
        console.log('发送结束标识')
        clearInterval(this.handlerInterval)
        return false
      }
      const audioData = buffer.splice(0, 1280)
      if (audioData.length > 0) {
        this.ws.send(new Int8Array(audioData))
      }
    }, 40)
  }

  wsOnMessage(e) {
    let jsonData = JSON.parse(e.data)
    if (jsonData.action === 'started') {
      // 握手成功
      console.log('握手成功')
    } else if (jsonData.action === 'result') {
      // 转写结果
      if (this.config.onMessage && typeof this.config.onMessage === 'function')
        this.config.onMessage(jsonData.data)
    } else if (jsonData.action === 'error') {
      // 连接发生错误
      console.log('出错了:', jsonData)
    }
  }
}
class IatTaste {
  constructor(funs) {
    const iatRecorder = new IatRecorder({
      onClose: () => {
        this.stop()
        this.reset()
      },
      onError: () => {
        this.stop()
        this.reset()
        alert('WebSocket连接失败')
      },
      onMessage: funs.onMessage,
      onStart: funs.onStart,
    })
    this.iatRecorder = iatRecorder
  }
  start() {
    this.iatRecorder.start()
  }
  stop() {
    this.iatRecorder.stop()
  }
  reset() {
    buffer = []
  }
}

export default {
  data() {
    return {
      popUp: false, //控制退出应用的弹窗
      interviewId: 0, // 0 = 游客 1 = 扫码
      indexCurr: 0, //控制题库的数组显示
      todayTime: null, // 今天时间
      todayS: null, // 今天秒数
      isShowH: false, // 是否显示矩形动效
      iatTaste: {},
      nets: 'tinyFaceDetector', // 模型
      options: null, // 模型参数
      detectFace: 'detectSingleFace', // 单or多人脸
      upyunUrl,
      upFileYunUrl,
      upyunData: null,
      videoEl: null,
      canvasEl: null,
      mediaStream: null,
      mediaRecorder: null,
      stopRecordCallback: null,
      recorderFile: null,
      callBackObj: null,
      currentIndex: 0,
      currentTime: 0,
      onTestStartTime: 60, // 游客倒计时答题
      h: '00',
      m: '00',
      s: '00',
      submitObj: {
        id: '', // 题目id
        channel: '', // 机器ID-渠道ID
        answer_video_url: [], // 每段视频的url
        answer_video_duration: [], // 每段视频的时长
        answer_video_score: [], // 每段视频的分数
        answer_phiz: [], // 每段视频捕捉到的表情
        answer_text: [], // 每段语音识别文字

        type: 1, // 1 = 全真面试, 2 = 普通模拟面试, 3 = ai求职面试, 4 = 初赛, 5 = 半决赛
        time_consuming: 0, // 面试时长
        recruitment_id: 0, // 套题id
        yz_activity_id: 0, // 大赛id
        is_web: 1,
      },
      temporary_phiz: '', // 临时表情存储
      temporary_text: '', // 临时语音转文字存储
      is_click: false, // 回答完毕是否能点击
      electrocardiogram1: {
        // 心电图
        flag: true,
        oCanvas: null, // 心电图canvas
        oContext: null, // 心电图2d图像
        wid: 0, // 浏览器可视区宽度
        hei: 0, // 浏览器可视区高度
        x: 0,
        y: 0,
      },
      electrocardiogram2: {
        // 心电图
        flag: true,
        oCanvas: null, // 心电图canvas
        oContext: null, // 心电图2d图像
        wid: 0, // 浏览器可视区宽度
        hei: 0, // 浏览器可视区高度
        x: 0,
        y: 0,
      },
      electrocardiogram3: {
        // 心电图
        flag: true,
        oCanvas: null, // 心电图canvas
        oContext: null, // 心电图2d图像
        wid: 0, // 浏览器可视区宽度
        hei: 0, // 浏览器可视区高度
        x: 0,
        y: 0,
      },
      excitedNum: 0, // 激动次数
      calmNum: 0, // 平静次数
      concernNum: 0, // 忧虑次数
      overallRating: 0, // 综合评分
      speakingRate: 0, // 说话语速
      weather: null, // 天气
      noFigureDuration: 30, // 多久检测不到人脸 / 秒
      noFigureSec: 0, // 未发现人脸时长
      isShowNoFigure: false, // 是否显示捕捉不到人脸的提示
      jobObj: {
        // 岗位对象
        navLists: [],
        jobLists: [],
      },
      drawerState: false, // 选择题目抽屉状态
      reportObj: null, // 报告对象
      drawerType: 'job', // 打开右边抽屉类型
    }
  },
  computed: {
    ...mapState(['channel', 'interviewToken']),
  },
  created() {
    const _this = this
    this.getUpYunSignFn()
    document.onkeydown = function (e) {
      if (
        (_this.is_click && e.keyCode === 13) ||
        (_this.is_click && e.keyCode === 38) ||
        (_this.is_click && e.keyCode === 40)
      )
        _this.onSaveFn() // 按向上下键
      if (
        (_this.interviewId === 0 && _this.onTestStartTime > 0 && e.keyCode === 13) ||
        (_this.interviewId === 0 && _this.onTestStartTime > 0 && e.keyCode === 38) ||
        (_this.interviewId === 0 && _this.onTestStartTime > 0 && e.keyCode === 40)
      )
        _this.onTestStartAnswerFn(false) // 游客开始答题
      if (e.keyCode === 27) _this.onOutPageFn()
    }
    if (this.$route.query.id) this.interviewId = parseInt(this.$route.query.id)
    this.getInterviewTrainingCategoryFn()
    this.getReportListFn()
  },
  mounted() {
    // 请求时间
    this.timeFn()
    this.showHeightFn()
    // this.getWeatherFn()
    if (
      (this.channel && this.interviewId === 0) ||
      (this.channel && this.interviewId === 1 && this.interviewToken)
    ) {
      this.$nextTick().then(() => {
        this.initializationFn()
      })
    }
    if (!this.channel) {
      const channel = localStorage.getItem('channel')
      if (channel) {
        this.$router.push(`/interview/welcome?channel=${channel}`)
      } else {
        this.$router.push(`/interview/welcome`)
      }
    }
  },
  methods: {
    ...mapActions([
      'getInterviewRecordQuestion',
      'getInterviewTrainingDetail',
      'getUpYunSign',
      'videoUpyun',
      'submitAnswer',
      'onSubmitInterviewTrainingSaveAnswer',
      'getInterviewTrainingCategory',
      'getListByCate',
      'getReportList',
      'emptyReportList',
    ]),
    // 监听打开退出弹窗
    onOutPageFn() {
      this.popUp = true
    },
    //当用户点击确定退出
    ensureFn() {
      history.go(-history.length + 1)
    },
    //当用户点击取消关闭退出应用的弹窗
    cancelfn(data) {
      this.popUp = data
    },
    // 平均分数
    averageScoreFn(averageNum) {
      let score = 0
      const num = this.overallRating
      const obj = this.callBackObj
      if (obj && obj.question.length) {
        const lists = this.submitObj.answer_video_score
        if (lists.length) score = eval(lists.join('+'))
        return Math.ceil((num + score) / obj.question.length / averageNum)
      }
    },
    // 获取天气
    async getWeatherFn() {
      let param = new URLSearchParams()
      param.append('cityId', 776)
      axios({
        method: 'post',
        url: 'https://aliv18.mojicb.com/whapi/json/alicityweather/condition',
        data: param,
        headers: { Authorization: 'APPCODE 4f8fc30ed0774c1aa58dd484c747e48f' },
      }).then(res => {
        this.weather = res.data.data.condition
      })
    },
    // 初始化数据
    initializationFn() {
      this.videoEl = document.getElementById('myVideo')
      if (this.interviewId) {
        this.drawerState = true
      } else {
        this.getInterviewTopicFn()
      }
      this.iatTasteFn()
      this.fnInit()
    },
    // 初始化模型加载
    async fnInit() {
      await faceapi.nets[this.nets].loadFromUri('/models') // 算法模型
      await faceapi.loadFaceLandmarkModel('/models') // 轮廓模型
      await faceapi.loadFaceExpressionModel('/models') // 表情模型
      await faceapi.loadAgeGenderModel('/models') // 年龄模型
      // 根据算法模型参数识别调整结果
      this.options = new faceapi.TinyFaceDetectorOptions({
        inputSize: 512, // 160 224 320 416 512 608
        scoreThreshold: 0.5, // 0.1 ~ 0.9
      })
      // 节点属性化
      // this.videoEl = document.getElementById('myVideo')
      this.canvasEl = document.getElementById('myCanvas')
    },
    // 人脸表情识别绘制
    async fnRunFaceExpression() {
      // 识别绘制人脸信息
      const result = await faceapi[this.detectFace](this.videoEl, this.options)
        .withFaceLandmarks()
        .withFaceExpressions()
      if (result && !this.videoEl.paused) {
        const dims = faceapi.matchDimensions(this.canvasEl, this.videoEl, true)
        const resizeResult = faceapi.resizeResults(result, dims)
        const phizText = faceapi.draw.drawFaceExpressions(this.canvasEl, resizeResult, 0.05)
        // neutral = 平静
        // happy = 开心
        // surprised = 惊讶
        // sad = 伤心
        this.temporary_phiz = phizText.split(' ')[0] // 表情添加
        this.isShowNoFigure = false
        this.noFigureSec = 0
      } else {
        this.canvasEl.getContext('2d').clearRect(0, 0, this.canvasEl.width, this.canvasEl.height)
        this.temporary_phiz = 'neutral' // 表情添加
        if (this.noFigureSec >= this.noFigureDuration && !this.isShowNoFigure && this.is_click)
          this.isShowNoFigure = true
      }
      timeout = setTimeout(() => {
        this.noFigureSec++
        this.fnRunFaceExpression()
        if (this.noFigureDuration * 2 - this.noFigureSec <= 0 && this.isShowNoFigure)
          location.reload()
      }, 1000)
    },
    // 获取又拍云签名
    async getUpYunSignFn() {
      this.upyunData = await this.getUpYunSign()
    },
    // 获取面试题目
    async getInterviewTopicFn(id) {
      if (id) {
        this.interviewId = id
        this.overallRating = 0
        this.speakingRate = 0
        this.temporary_phiz = ''
        this.temporary_text = ''
        this.currentIndex = 0
        this.currentTime = 0
      }
      if (this.interviewId) {
        this.drawerState = false
        this.getInterviewTrainingDetailFn()
      } else {
        this.getInterviewRecordQuestionFn()
      }
    },
    // 获取本地题目
    async getInterviewRecordQuestionFn() {
      const data = {
        channel: this.channel || null,
      }
      this.callBackObj = await this.getInterviewRecordQuestion(data)
      if (this.callBackObj.question.length) {
        this.callBackObj.question.forEach((item, index) => {
          item.indexId = index + 1
        })
        this.onOpenCameraFn()
      }
      this.submitObj.id = this.callBackObj.id
    },
    // 获取面试有招题目
    async getInterviewTrainingDetailFn() {
      // 当面试招聘切换面试岗位的时候 把indexCurr记录的下标清0 把之前的面试题目清空
      this.indexCurr = 0
      this.submitObj.answer_video_url = []
      const data = {
        id: this.interviewId,
        type: 0,
        yz_activity_id: null,
        channel: this.channel || null,
      }
      this.callBackObj = await this.getInterviewTrainingDetail(data)
      if (this.callBackObj.question.length) {
        this.callBackObj.question.forEach((item, index) => {
          item.indexId = index + 1
        })
        this.onOpenCameraFn()
      }
      this.submitObj.id = this.callBackObj.id
    },
    // 创建语音转文字
    iatTasteFn() {
      const _this = this
      this.iatTaste = new IatTaste({
        onStart: () => {
          _this.temporary_text = ''
          console.log('onStart')
        },
        onMessage: message => {
          const data = JSON.parse(message)
          let rtasrResult = []
          rtasrResult[data.seg_id] = data
          rtasrResult.forEach(i => {
            let str = ''
            i.cn.st.rt.forEach(j => {
              j.ws.forEach(k => {
                k.cw.forEach(l => {
                  str += l.w
                })
              })
            })
            if (_this.is_click) {
              _this.temporary_text += str
              if (_this.channel === nnHuaWeiChannel) {
                for (let i = 0; i < randomNumS.length; i++) {
                  let randNum = Math.floor(Math.random() * randomNumS.length)
                  _this.overallRating = randomNumS[randNum]
                }
              } else {
                _this.overallRating = _this.getScoreFn(
                  _this.temporary_text,
                  _this.currentTime,
                  _this.callBackObj.question[_this.currentIndex].point_word
                ) // 获取当前题目综合分
              }
            }
          })
        },
      })
    },
    // 打开摄像头
    onOpenCameraFn() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then(this.success)
          .catch(this.error)
      } else {
        alert('不支持访问用户媒体')
      }
    },
    success(stream) {
      let chunks = []
      this.mediaRecorder = new MediaRecorder(stream)
      this.videoEl.srcObject = stream
      this.mediaStream = stream
      this.mediaRecorder.ondataavailable = e => {
        chunks.push(e.data)
      }
      this.mediaRecorder.onstop = () => {
        this.recorderFile = new Blob(chunks, {
          type: this.mediaRecorder.mimeType,
        })
        chunks = []
        if (this.stopRecordCallback != null) this.stopRecordCallback()
      }
      // 开始录像
      if (this.interviewId === 0 && this.currentIndex === 0) {
        this.onTestStartAnswerFn(false)
      } else {
        this.mediaRecorder.start()
        this.musicPlayFn()
      }
    },
    error(error) {
      alert(`请到应用管理将相机和录音授权允许!`)
      console.log(`访问用户媒体设备失败${error.name}, ${error.message}`)
    },
    /**
     * 关闭媒体流
     * @param stream {MediaStream} - 需要关闭的流
     */
    closeStream(stream) {
      if (typeof stream.stop === 'function') {
        stream.stop()
      } else {
        let trackList = [stream.getAudioTracks(), stream.getVideoTracks()]
        for (let i = 0; i < trackList.length; i++) {
          let tracks = trackList[i]
          if (tracks && tracks.length > 0) {
            for (let j = 0; j < tracks.length; j++) {
              let track = tracks[j]
              if (typeof track.stop === 'function') track.stop()
            }
          }
        }
      }
    },
    // 倒计时游客答题
    onTestStartAnswerFn(state) {
      if (state) {
        this.onTestStartTime -= 1
        clearInterval(startTimer)
        startTimer = setInterval(() => {
          if (this.onTestStartTime === 0) {
            clearInterval(startTimer)
            this.mediaRecorder.start()
            this.musicPlayFn()
          } else {
            this.onTestStartAnswerFn(state)
          }
        }, 1000)
      } else {
        this.onTestStartTime = 0
        clearInterval(startTimer)
        this.mediaRecorder.start()
        this.musicPlayFn()
      }
    },
    // 语音播报
    musicPlayFn() {
      const url = this.callBackObj.question[this.currentIndex].female_audio
      if (url) {
        myAudio.src = url
        myAudio.play()
        myAudio.addEventListener('ended', () => {
          this.electrocardiogramStart1Fn() // 开始绘制心点图
          this.electrocardiogramStart2Fn() // 开始绘制心点图
          this.electrocardiogramStart3Fn() // 开始绘制心点图
          this.is_click = true
          this.timingStart()
        })
      } else {
        this.electrocardiogramStart1Fn() // 开始绘制心点图
        this.electrocardiogramStart2Fn() // 开始绘制心点图
        this.electrocardiogramStart3Fn() // 开始绘制心点图
        this.is_click = true
        this.timingStart()
      }
      setTimeout(() => {
        this.onStartIatTasteFn()
      }, 5000)
    },
    // 开始语音转文字：需要整改
    onStartIatTasteFn() {
      console.log('------->开启语音中...')
      if (navigator.getUserMedia && AudioContext && recorderWorker) this.iatTaste.start()
    },
    // 停止录制
    stopRecord(callback) {
      this.stopRecordCallback = callback
      // 终止录制器
      this.mediaRecorder.stop()
      // 关闭媒体流
      this.closeStream(this.mediaStream)
    },
    // 开始计时
    timingStart() {
      clearInterval(timer)
      timer = setInterval(() => {
        this.submitObj.time_consuming++
        this.currentTime++
        // 需要改变页面上时分秒的值
        this.s = this.showNum(this.submitObj.time_consuming % 60)
        this.m = this.showNum(parseInt(this.submitObj.time_consuming / 60) % 60)
        this.h = this.showNum(parseInt(this.submitObj.time_consuming / 60 / 60))
      }, 1000)
    },
    // 处理单位数字的函数
    showNum(num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },
    // 存储到又拍云
    async onSaveFn() {
      clearInterval(timer)
      clearTimeout(timeout)
      this.is_click = false
      // // 结束语音转文字
      this.iatTaste.stop()
      buffer = []
      // // 结束
      this.stopRecord(() => {
        this.generateFileFn()
        // this.is_click = false
      })
    },
    // 生成文件
    async generateFileFn() {
      const index = this.currentIndex
      const lists = this.callBackObj.question
      const data = new FormData()
      const file = new File(
        [this.recorderFile],
        'msr-' + new Date().toISOString().replace(/:|\./g, '-') + '.mp4',
        {
          type: 'video/mp4',
        }
      )
      data.append('file', file)
      data.append('policy', this.upyunData.policy)
      data.append('signature', this.upyunData.signature)

      const obj = await this.videoUpyun(data)
      if (this.interviewId === 0) {
        this.submitObj.answer_text.push(this.temporary_text) // 文字
      } else if (this.interviewId) {
        const answerText = {
          duration: this.currentTime,
          answer_text: this.temporary_text,
          question_id: lists[index].id,
        }
        this.submitObj.answer_text.push(answerText)
      }
      // this.submitObj.answer_phiz.push(this.temporary_phizs) // 表情
      this.submitObj.answer_video_duration.push(this.currentTime) // 时间
      this.submitObj.answer_video_score.push(this.overallRating) // 分数
      if (this.interviewId === 0) {
        this.submitObj.answer_video_url.push({
          id: this.callBackObj.question[this.currentIndex].id,
          url: this.upFileYunUrl + obj.url,
        }) // 视频url
      } else if (this.interviewId) {
        this.submitObj.answer_video_url.push({
          id: this.callBackObj.question[this.currentIndex].id,
          urls: [this.upFileYunUrl + obj.url],
        }) // 视频url
      }
      this.replyStatusFn()
    },
    // 回答题目状态
    async replyStatusFn() {
      if (this.submitObj.answer_video_url.length === this.callBackObj.question.length) {
        this.onSubmitAnswerFn()
      } else {
        this.overallRating = 0
        this.speakingRate = 0
        this.temporary_phiz = ''
        this.temporary_text = ''
        this.currentIndex += 1
        // 屏幕控制显示第几题
        this.indexCurr += 1
        //进行题库数组的显示处理
        if (this.currentIndex === 3) {
          this.currentIndex = 0
          let hiddenItems = this.callBackObj.question.splice(0, 3)
          this.callBackObj.question = this.callBackObj.question.concat(hiddenItems)
        }
        this.currentTime = 0
        this.onOpenCameraFn()
      }
    },
    // 提交答案
    onSubmitAnswerFn() {
      if (this.channel) this.submitObj.channel = this.channel
      if (this.interviewId) {
        this.onSubmitInterviewTrainingSaveAnswerFn()
      } else {
        this.submitAnswerFn()
      }
    },
    // 提交到本地
    async submitAnswerFn() {
      await this.submitAnswer(this.submitObj)
      this.submitObj.time_consuming = 0
      this.$message({
        message: '已将数据同步到数据库',
        type: 'success',
      })
      setTimeout(() => {
        localStorage.setItem('isAnswerCompleted', true)
        this.$router.push({
          query: {
            id: this.interviewId,
          },
          path: '/interview/view_the_report',
        })
      }, 1000)
    },
    // 提交到面试有招
    async onSubmitInterviewTrainingSaveAnswerFn() {
      await this.onSubmitInterviewTrainingSaveAnswer(this.submitObj)
      this.submitObj.time_consuming = 0
      this.$message({
        message: '已将数据同步到面试有招',
        type: 'success',
      })
      setTimeout(() => {
        localStorage.setItem('isAnswerCompleted', true)
        this.$router.push({
          query: {
            id: this.interviewId,
          },
          path: '/interview/view_the_report',
        })
      }, 1000)
    },
    // 本地时间
    timeFn() {
      setInterval(() => {
        this.todayTime = formatTime(new Date(), 'HH: mm')
        this.todayS = formatTime(new Date(), ': ss')
      }, 1000)
    },
    // 矩形动效
    showHeightFn() {
      setTimeout(() => {
        this.isShowH = true
      }, 1000)
    },
    // 文本 时长s 关键字array
    getScoreFn(str, time, keyWords) {
      let strLong = str.length
      let score = 0
      let decimal = Math.ceil(Math.random() * 100) / 100

      if (strLong < 10) {
        score = strLong
        score = parseInt(score)
        return score - decimal
      }
      // 语速
      score = 40
      let speakingRate = parseInt(strLong / (time / 60))
      if (speakingRate > 140) speakingRate = 140 - Math.floor(Math.random() * 30)
      this.speakingRate = speakingRate
      // 基准300
      if (this.speakingRate < 300 * 0.6) {
        score -= 30
      } else if (this.speakingRate < 300 * 0.7) {
        score -= 20
      } else if (this.speakingRate < 300 * 0.8) {
        score -= 10
      } else if (this.speakingRate < 300 * 0.9) {
        score -= Math.abs(40 * ((this.speakingRate - 500) / 500))
      }
      // 过大
      // if(this.speakingRate > 500) score -= Math.abs(50*((this.speakingRate-500)/500))
      //断句过多 不流畅
      let tt = str.replace(
        /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,
        ''
      )
      if (strLong - tt.length && strLong / (strLong - tt.length) < 6) score -= 20
      //关键词数量
      const key = this.matchKey(str, keyWords)
      score += 13 * key
      //词语过多
      if (strLong > 600) {
        score -= (strLong - 600) / 5
      }
      // 极限错误
      if (score > 96) score = 95
      if (score < 1) score = 2
      score -= decimal
      if (score < 0) score = 0
      return score
    },
    // 匹配字符次数
    matchKey(str, keyWords) {
      let times = 0
      keyWords.forEach(item => {
        if (str.indexOf(item) !== -1) {
          times++
        }
      })
      return times
    },
    // 心电图开始绘制
    electrocardiogramStart1Fn() {
      this.electrocardiogram1.oCanvas = document.getElementById('can1')
      if (this.$refs.tableCanvas) this.electrocardiogram1.wid = this.$refs.tableCanvas.offsetWidth
      if (this.$refs.tableCanvas) this.electrocardiogram1.hei = this.$refs.tableCanvas.offsetHeight
      this.electrocardiogram1.y = this.electrocardiogram1.hei / 2
      this.electrocardiogram1.oCanvas.width = this.electrocardiogram1.wid
      this.electrocardiogram1.oCanvas.height = this.electrocardiogram1.hei
      this.electrocardiogram1.oContext = this.electrocardiogram1.oCanvas.getContext('2d') // 获取2D图像API接口
      this.electrocardiogram1.oContext.strokeStyle = '#3bff72' // 设置画笔颜色
      this.electrocardiogram1.oContext.lineJoin = 'round' // 设置画笔轨迹基于圆点拼接
      this.electrocardiogram1.oContext.lineWidth = 2 // 设置画笔粗细
      this.electrocardiogram1.oContext.beginPath()
      this.electrocardiogram1.oContext.moveTo(this.electrocardiogram1.x, this.electrocardiogram1.y)
      clearInterval(electrocardiogramTimer1)
      electrocardiogramTimer1 = setInterval(() => {
        this.electrocardiogramMove1Fn()
      }, 1)
    },
    // 移动心电图
    electrocardiogramMove1Fn() {
      this.electrocardiogram1.x++
      const canvasH = this.electrocardiogram1.hei
      const y = this.electrocardiogram1.y
      if (this.electrocardiogram1.x < this.electrocardiogram1.wid) {
        if (this.temporary_phiz === 'neutral') this.electrocardiogram1.y = canvasH / 2
        if (this.temporary_phiz !== 'neutral') {
          if (parseInt(Math.random() * 2) === 0 && y > 0) this.electrocardiogram1.y -= 5
          if (parseInt(Math.random() * 2) === 1 && y < canvasH) this.electrocardiogram1.y += 5
          if (this.electrocardiogram1.flag) {
            this.electrocardiogram1.flag = false
            this.excitedNum += parseInt(Math.random() * 3)
            clearTimeout(electrocardiogramStop1)
            electrocardiogramStop1 = setTimeout(() => {
              this.electrocardiogram1.flag = true
              clearTimeout(electrocardiogramStop1)
              electrocardiogramStop1 = null
            }, 5000)
          }
        }
      }
      if (this.electrocardiogram1.x === this.electrocardiogram1.wid) {
        this.electrocardiogram1.oContext.closePath()
        clearInterval(electrocardiogramTimer1)
        electrocardiogramTimer1 = null
        this.electrocardiogram1.x = 0
        this.electrocardiogram1.y = this.electrocardiogram1.hei / 2
        this.electrocardiogramStart1Fn()
      }
      this.electrocardiogram1.oContext.lineTo(this.electrocardiogram1.x, this.electrocardiogram1.y)
      this.electrocardiogram1.oContext.stroke()
    },
    // 心电图开始绘制
    electrocardiogramStart2Fn() {
      this.electrocardiogram2.oCanvas = document.getElementById('can2')
      if (this.$refs.tableCanvas) this.electrocardiogram2.wid = this.$refs.tableCanvas.offsetWidth
      if (this.$refs.tableCanvas) this.electrocardiogram2.hei = this.$refs.tableCanvas.offsetHeight
      this.electrocardiogram2.y = this.electrocardiogram2.hei / 2
      this.electrocardiogram2.oCanvas.width = this.electrocardiogram2.wid
      this.electrocardiogram2.oCanvas.height = this.electrocardiogram2.hei
      this.electrocardiogram2.oContext = this.electrocardiogram2.oCanvas.getContext('2d') // 获取2D图像API接口
      this.electrocardiogram2.oContext.strokeStyle = '#ff6800' // 设置画笔颜色
      this.electrocardiogram2.oContext.lineJoin = 'round' // 设置画笔轨迹基于圆点拼接
      this.electrocardiogram2.oContext.lineWidth = 2 // 设置画笔粗细
      this.electrocardiogram2.oContext.beginPath()
      this.electrocardiogram2.oContext.moveTo(this.electrocardiogram2.x, this.electrocardiogram2.y)
      clearInterval(electrocardiogramTimer2)
      electrocardiogramTimer2 = setInterval(() => {
        this.electrocardiogramMove2Fn()
      }, 1)
    },
    // 移动心电图
    electrocardiogramMove2Fn() {
      this.electrocardiogram2.x++
      const canvasH = this.electrocardiogram2.hei
      const y = this.electrocardiogram2.y
      if (this.electrocardiogram2.x < this.electrocardiogram2.wid) {
        if (this.temporary_phiz === 'neutral') this.electrocardiogram2.y = canvasH / 2
        if (this.temporary_phiz !== 'neutral') {
          if (parseInt(Math.random() * 2) === 0 && y > 0) this.electrocardiogram2.y -= 5
          if (parseInt(Math.random() * 2) === 1 && y < canvasH) this.electrocardiogram2.y += 5
          if (this.electrocardiogram2.flag) {
            this.electrocardiogram2.flag = false
            this.calmNum += parseInt(Math.random() * 6)
            clearTimeout(electrocardiogramStop2)
            electrocardiogramStop2 = setTimeout(() => {
              this.electrocardiogram2.flag = true
              clearTimeout(electrocardiogramStop2)
              electrocardiogramStop2 = null
            }, 5000)
          }
        }
      }
      if (this.electrocardiogram2.x === this.electrocardiogram2.wid) {
        this.electrocardiogram2.oContext.closePath()
        clearInterval(electrocardiogramTimer2)
        electrocardiogramTimer2 = null
        this.electrocardiogram2.x = 0
        this.electrocardiogram2.y = this.electrocardiogram2.hei / 2
        this.electrocardiogramStart2Fn()
      }
      this.electrocardiogram2.oContext.lineTo(this.electrocardiogram2.x, this.electrocardiogram2.y)
      this.electrocardiogram2.oContext.stroke()
    },
    // 心电图开始绘制
    electrocardiogramStart3Fn() {
      this.electrocardiogram3.oCanvas = document.getElementById('can3')
      if (this.$refs.tableCanvas) this.electrocardiogram3.wid = this.$refs.tableCanvas.offsetWidth
      if (this.$refs.tableCanvas) this.electrocardiogram3.hei = this.$refs.tableCanvas.offsetHeight
      this.electrocardiogram3.y = this.electrocardiogram3.hei / 2
      this.electrocardiogram3.oCanvas.width = this.electrocardiogram3.wid
      this.electrocardiogram3.oCanvas.height = this.electrocardiogram3.hei
      this.electrocardiogram3.oContext = this.electrocardiogram3.oCanvas.getContext('2d') // 获取2D图像API接口
      this.electrocardiogram3.oContext.strokeStyle = '#fcc61c' // 设置画笔颜色
      this.electrocardiogram3.oContext.lineJoin = 'round' // 设置画笔轨迹基于圆点拼接
      this.electrocardiogram3.oContext.lineWidth = 2 // 设置画笔粗细
      this.electrocardiogram3.oContext.beginPath()
      this.electrocardiogram3.oContext.moveTo(this.electrocardiogram3.x, this.electrocardiogram3.y)
      clearInterval(electrocardiogramTimer3)
      electrocardiogramTimer3 = setInterval(() => {
        this.electrocardiogramMove3Fn()
      }, 1)
    },
    // 移动心电图
    electrocardiogramMove3Fn() {
      this.electrocardiogram3.x++
      const canvasH = this.electrocardiogram3.hei
      const y = this.electrocardiogram3.y
      if (this.electrocardiogram3.x < this.electrocardiogram3.wid) {
        if (this.temporary_phiz === 'neutral') this.electrocardiogram3.y = canvasH / 2
        if (this.temporary_phiz !== 'neutral') {
          if (parseInt(Math.random() * 2) === 0 && y > 0) this.electrocardiogram3.y -= 5
          if (parseInt(Math.random() * 2) === 1 && y < canvasH) this.electrocardiogram3.y += 5
          if (this.electrocardiogram3.flag) {
            this.electrocardiogram3.flag = false
            this.concernNum += parseInt(Math.random() * 3)
            clearTimeout(electrocardiogramStop3)
            electrocardiogramStop3 = setTimeout(() => {
              clearTimeout(electrocardiogramStop3)
              electrocardiogramStop3 = null
              this.electrocardiogram3.flag = true
            }, 5000)
          }
        }
      }
      if (this.electrocardiogram3.x === this.electrocardiogram3.wid) {
        this.electrocardiogram3.oContext.closePath()
        clearInterval(electrocardiogramTimer3)
        electrocardiogramTimer3 = null
        this.electrocardiogram3.x = 0
        this.electrocardiogram3.y = this.electrocardiogram3.hei / 2
        this.electrocardiogramStart3Fn()
      }
      this.electrocardiogram3.oContext.lineTo(this.electrocardiogram3.x, this.electrocardiogram3.y)
      this.electrocardiogram3.oContext.stroke()
    },
    // 获取面试岗位分类
    async getInterviewTrainingCategoryFn() {
      const data = {
        from_view: 2,
        channel: this.channel,
      }
      this.jobObj['navLists'] = await this.getInterviewTrainingCategory(data)
      this.getListByCateFn('')
    },
    // 获取面试岗位列表
    async getListByCateFn(category_id) {
      const data = {
        channel: this.channel,
        category_id,
        page_size: 9999,
        page: 1,
      }
      this.jobObj['jobLists'] = await this.getListByCate(data)
    },
    // 获取面试列表
    async getReportListFn() {
      const data = {
        channel: this.channel,
        page_size: 12,
        page: 1,
      }
      this.reportObj = await this.getReportList(data)
    },
    // 清空报告列表
    onEmptyDataFn() {
      const _this = this
      this.$confirm('是否清空数据?')
        .then(_ => {
          _this.emptyReportListFn()
        })
        .catch(_ => {})
    },
    // 清空报告列表
    async emptyReportListFn() {
      const data = {
        channel: this.channel,
      }
      await this.emptyReportList(data)
      this.onHideDrawerFn()
      this.getReportListFn()
    },
    // 打开右边抽屉
    onDrawerFn(type) {
      if (type === 'report' && this.reportObj.data.length === 0) {
        this.$message.error('暂无面试记录')
        return false
      }
      this.drawerType = type
      this.drawerState = true
    },
    // 关闭右边抽屉
    onHideDrawerFn() {
      if (this.interviewId && !this.callBackObj) {
        this.$message.error('请选择面试题目后，才能点击关闭')
        return false
      }
      this.drawerState = false
    },
    // 重新加载页面
    onReloadFn() {
      location.reload()
    },
  },
  components: {
    CountTo,
    JobListTemplate,
    ReportTemplate,
    ExitApplication, //退出应用组件
  },
}
</script>

<style lang="stylus" scoped>
.index-wrapper
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  z-index 99
  .screen-wrapper
    position relative
    width 100%
    height 100%
    padding 36px 50px 30px
    box-sizing border-box
    .bg
      position absolute
      left 0
      top 0
      bottom 0
      z-index -1
      width 100%
      height 100%
    .loading
      position fixed
      left 0
      top 0
      width 100%
      height 100%
      display flex
      align-items center
      justify-content center
      z-index 999
      background-color #000
      .loading-text
        font-weight bold
        line-height 100px
        font-size 30px
        text-align center
        opacity 0.8
        color #fff
      .start-btn
        width 100%
        height auto
        display flex
        img
          position relative
          width 200px
          height auto
          display block
          cursor pointer
          margin 0 30px
          z-index 10
  .common-style
    font-size 20px
    color #fff
  .content
    width 100%
    height 100%
    display flex
    .left-box
      flex 1
      .title
        font-size 40px
        color #F9F9FC
      .data-box
        display flex
        width 100%
        height auto
        margin 20px 0 54px 0
        .item
          display flex
          align-items center
          padding-right 60px
          box-sizing border-box
          .icon
            width 74px
            height 71px
            animation pulse 3s linear infinite
          .num-box
            flex 1
            padding-left 18px
            box-sizing border-box
            text-align center
            .num
              span
                font-size 60px
                color #3BFF72
            .text
              font-size 28px
              color #A7A8A8
            .heart-num
              width 139px
          .line-box
            width 1px
            height 140px
            .line
              width 3px
              height 13px
              background #fff
            .small
              width 2px
              height 9px
          .desc-box
            padding-left 16px
            box-sizing border-box
            .num-box
              text-align left
              display flex
              align-items center
              margin-bottom 22px
              .schedule
                position relative
                width 9px
                height 43px
                background #aaacad
                .active
                  position absolute
                  bottom 0
                  left 0
                  width 100%
                  height 20%
                  background #3bff72
              .text-box
                padding-left 11px
                box-sizing border-box
                .num
                  display flex
                  align-items center
                  font-size 16px
                  color #fff
                  span
                    font-size 30px
                    color #fff
                    padding-right 3px
                    box-sizing border-box
                  .icon
                    margin-left 3px
                    width 9px
                    height 8px
                .text
                  font-size 16px
            .num-box:last-child
              margin-bottom 0
      .mental-box
        width 100%
        height auto
        padding 16px 0
        box-sizing border-box
        border-top 1px solid #414648
        .item
          display flex
          align-items center
          margin-bottom 28px
          .score
            width 48px
            font-size 50px
            color #ff6800
          .text-box
            width 115px
            padding 0 30px 0 16px
            box-sizing border-box
            .dot-box
              display flex
              align-items center
              .dot
                width 6px
                height 6px
                background #ff6800
                border-radius 50%
              .text
                padding-left 5px
                box-sizing border-box
                font-size 16px
                color #A7A8A8
          .table-box
            flex 1
            position relative
            height 65px
            border 1px solid #293031
            .table-canvas
              position absolute
              left 0
              top 0
              z-index 10
            .row
              display flex
              width 100%
              height 100%
              padding-right 32px
              box-sizing border-box
              .line
                flex 1
                border-right 1px solid #293031
            .vertical
              position absolute
              top 0
              width 100%
              height 100%
              .line
                margin-top 15px
                border-bottom 1px solid #293031
        .item:first-child
          .score
            color #3bff72
          .text-box
            .dot-box
              .dot
                background #3bff72
        .item:last-child
          .score
            color #fcc61c
          .text-box
            .dot-box
              .dot
                background #fcc61c
      .answer-box
        width 100%
        height auto
        padding 16px 0 32px
        box-sizing border-box
        border-top 1px solid #414648
        .item
          position relative
          display flex
          justify-content space-between
          padding-bottom 12px
          box-sizing border-box
          margin-bottom 14px
          border-bottom 1px solid #6d7174
          .item-left
            .desc
              margin 2px 0 7px
              font-size 14px
              color #676C71
            .hint-box
              display flex
              align-items center
              .hint
                display flex
                align-items center
                padding-right 15px
                box-sizing border-box
                .icon
                  width 8px
                  height 6px
                .text
                  padding-left 3px
                  box-sizing border-box
                  font-size 12px
                  color #fff
          .item-right
            padding-right 4px
            box-sizing border-box
            color #fff
            text-align center
            .num
              width 65px
              font-size 36px
            .text
              font-size 19px
          .line
            position absolute
            bottom 0
            right 0
            width 7px
            height 2px
            background #fff
    .center
      flex 1
      .mobile-phone-frame
        position relative
        width 100%
        height auto
        .flat-bangs-view
          position absolute
          left -2px
          top 4.9%
          width 100%
          height auto
          z-index 99
          .flat-bangs
            width 38%
            height auto
            margin 0 auto
            display block
            transform translateY(1.5px)
        .interview-view
          position absolute
          left 0
          top 5%
          bottom 9.7%
          width 100%
          height auto
          transform rotateY(180deg)
          z-index 98
          .my-video
            position relative
            width 57.7%
            height 100%
            margin 0 auto
            transform translate(2px, 2px)
            .my-video-loading
              position absolute
              left 0
              top 0
              width 100%
              height 100%
              z-index 999
              transform rotateY(180deg)
              border-radius 24px
              background-color rgba(0, 0, 0, 0.5)
              .loading-text
                text-align center
                line-height 50px
                color #fff
            .text-view
              position absolute
              left 0
              top 0
              width 100%
              height 100%
              padding 13% 5%
              border 2px solid transparent
              border-radius 25px
              z-index 10
              transform rotateY(180deg)
              color #fff
              box-sizing border-box
              &.view-border
                animation showHide 1.5s linear infinite
                border-color #ff0000
              .title
                font-weight bold
              .text
                padding-top 10px
              .bottom
                position absolute
                left 0
                bottom 3%
                width 100%
                height auto
                padding 0 5%
                display flex
                box-sizing border-box
                .left
                  width 140px
                  padding 10px 5px 0 5px
                  border-radius 10px
                  background-color rgba(0, 0, 0, 0.1)
                  box-sizing border-box
                  ul
                    width 100%
                    height auto
                    display flex
                    li
                      flex 1
                      width auto
                      height auto
                      text-align center
                      .groove
                        width 10px
                        height 50px
                        margin 0 auto
                        border-radius 5px
                        display flex
                        flex-flow column
                        justify-content flex-end
                        background-color rgba(255, 255, 255, 0.1)
                        .groove-line
                          width 100%
                          border-radius 5px
                          background-color #fff
                      .bottom-text
                        font-size 14px
                        line-height 30px
                .right
                  flex 1
                  text-align center
                  font-size 26px
                  font-weight bold
                  line-height 80px
            video
              width 100%
              height 100%
              display block
              object-fit cover
              border-radius 24px
          canvas
            position absolute
            left 0
            top 0
            opacity 0
        .mobile-img
          width 100%
          height auto
      .btn-wrapper
        width 100%
        height auto
        display flex
        justify-content center
        .btn-box
          width 100px
          height auto
          margin 0 20px
          padding-top 50px
          cursor pointer
          img
            display block
            width 100%
            height auto
    .right-box
      flex 1
      .daily-box
        display flex
        align-items center
        justify-content flex-end
        .item
          display flex
          align-items center
          .icon
            width 24px
            height 24px
            margin-right 10px
          .time-box
            display flex
            align-items center
            color #fff
            .hour
              font-size 20px
            .second
              padding-left 2px
              box-sizing border-box
              text-align center
              .text
                font-size 12px
              .line
                width 16px
                height 2px
                margin-top 3px
                background #3bff72
          .num
            display flex
            align-items center
            font-size 16px
            color #3bff72
            span
              padding-right 3px
              box-sizing border-box
              font-size 20px
              color #fff
        .right-icon
          margin 0 30px 0 24px
          width 16px
          height 16px
      .score-data
        display flex
        align-items flex-end
        width 100%
        height auto
        margin-top 0
        .score-box
          padding-right 13px
          box-sizing border-box
          color #fff
          .score
            font-size 50px
            span
              font-size 70px
          .text
            font-size 26px
            span
              color #3BFF72
        .text-box
          text-align right
          margin-bottom 4px
          .btn-box
            display flex
            justify-content flex-end
            margin-bottom 30px
            .btn
              width 44px
              line-height 22px
              margin-left 18px
              font-size 14px
              font-weight 600
              text-align center
              color #14181A
              cursor pointer
              background #fff
            .green-btn
              background #3BFF72
          .text
            margin-bottom 6px
            font-size 22px
            color #fff
          .block-box
            display flex
            align-items flex-end
            .block
              width 3px
              height 13px
              margin-right 5px
              background #3BFF72
              transform rotate(26deg)
            .last-block
              margin-right 0
            .line
              flex 1
              height 2px
              margin-left -6px
              background #3BFF72
      .item-title
        font-size 26px
        color #fff
        span
          color #3bff72
      .condition-box
        display flex
        margin 30px 0 20px
        .item
          position relative
          width 88px
          height 110px
          margin-right 20px
          border 1px solid #3bff72
          color #fff
          border-radius 10px
          .icon
            width 40px
            height 40px
            margin 10px auto 3px
          .text
            font-size 14px
            text-align center
          .num
            position absolute
            bottom 0
            left 0
            width 100%
            height 22px
            background rgba(59, 255, 114, 0.2)
            font-size 16px
            text-align center
            border-radius 0 0 10px 10px
        .item:nth-child(4)
          border 1px solid #01E1FB
          .num
            background rgba(1, 225, 251, 0.2)
        .item:last-child
          margin-right 0
          border 1px solid #01E1FB
          .num
            background rgba(1, 225, 251, 0.2)
      .problem-box
        width 100%
        height 100px
        margin 14px 0 30px
        display flex
        flex-flow column
        justify-content flex-end
        .line-box
          padding-right 50px
          display flex
          justify-content space-between
          box-sizing border-box
          .line
            display flex
            align-items flex-end
            justify-content center
            width 42px
            .line-view
              width 10px
              height 2px
              background-color #999
            .active
              background linear-gradient(0deg, #0E646F, #01E1FB)
              transition height 0.5s ease-in-out
          .line:last-child
            margin-right 0
        .text-box
          padding-right 50px
          margin-top 6px
          display flex
          justify-content space-between
          box-sizing border-box
          .text
            width 42px
            font-size 14px
            text-align center
            color #57646E
          .text:last-child
            margin-right 0
      .ability-box
        display flex
        margin 26px 0 30px
        .item
          margin-right 18px
          .num
            width 130px
            height 130px
            font-size 18px
            color #3bff72
          .text
            margin-top 3px
            font-size 14px
            text-align center
            color #5E6A73
        .item:last-child
          margin-right 0
      .topic-list
        width 100%
        height auto
        margin-top 14px
        ul
          li
            margin-bottom 14px
            cursor pointer
            .span
              display inline-block
              padding 0 10px
              box-sizing border-box
              font-size 14px
              line-height 38px
              color #fff
              border-radius 3px
          .active
            .span
              color #34271D
              font-weight 600
              background-color #3bff72
  .right-btn-wrapper
    position fixed
    right 14px
    top 20%
    width 70px
    height 627px
    z-index 10
    .bg
      width 26px
      height 100%
    .drawer-btn-box
      position absolute
      right 0
      top 0
      width 54px
      height 100%
      display flex
      flex-direction column
      align-items center
      justify-content center
      .item
        margin-bottom 30px
        cursor pointer
        img
          width 54px
          height 54px
        span
          display block
          width 100%
          height auto
          padding-top 4px
          line-height 14px
          text-align center
          font-size 12px
          color #fff
      .item:last-child
        margin-bottom 0
  .drawer-bg
    position relative
    width 100%
    height 100%
    padding 0 30px 0 50px
    box-sizing border-box
    .drawer-title
      padding-top 20px
      font-size 30px
      font-weight bold
      display flex
      justify-content space-between
      align-items center
      color #fff
      .empty-btn
        width 30px
        height 30px
        border-radius 50%
        cursor pointer
        display flex
        align-items center
        justify-content center
        z-index 99
        box-shadow 0 6px 10px 0 rgba(95, 101, 105, 0.15)
        background-color #ffdd38
        img
          width 20px
          height 20px
  .bg-img
    position absolute
    left 0
    top 50%
    width 39px
    height 628px
    margin-top -314px
    z-index 10
    .bg
      width 100%
      height 100%
@keyframes pulse
  from
    transform scale3d(1, 1, 1)
  50%
    transform scale3d(1.08, 1.1, 1.08)
  to
    transform scale3d(1, 1, 1)
@keyframes showHide
  from
    opacity 0
  50%
    opacity 1
  to
    opacity 0
</style>
